import { findByLabelText } from '@testing-library/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Link} from 'react-router-dom';

import axios from 'axios'
import Logout from '../logout/logout';

import { activateUser, deactivateUser } from '../../actions/auth';


const ActivateUsers = props => {
   
    const isLoggedIn = useSelector(state => state.isLoggedIn)
    const userData = useSelector(state => state.user)
    const [activeUsers, setActiveUsers] = useState()
    const [inactiveUsers, setInactiveUsers] = useState()
    const [currEmail, setCurrEmail ] = useState({
        email: ''
    })

    useEffect(() =>{
        getApiData()
        getInactive()
        
       }, [])
   
    const dispatch = useDispatch()
    // Function to collect data
const getApiData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_ACTIVE_USR}`
    ).then((response) => response.json({}));
  
    // update the state
    setActiveUsers(response.user);
    
  };

  const getInactive = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_INACTIVE_USR}`
    ).then((response) => response.json({}));
  
    // update the state
    setInactiveUsers(response.inactiveuser);

    
  };

  const checkEmail = (e, email) => {
      
       setCurrEmail({email});
    

    let formData = {email: email};
    

    axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_DEACTIVATE}`,
        data: formData
    }).then(response => {
        getApiData()
        getInactive()
    });
  
}
     

function impersonate(id) {

}

const activateUser = (e, email) => {
   
    setCurrEmail({email});
    let formData = {email: email};
    
    axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_ACTIVATE}`,
        data: formData
    }).then(response => {
        getApiData()
        getInactive()
    });
    
}


const tdStyle = {
    border: 'none',
    width: '300px',
    color: '#726658',
    margin: '0px 0px 48px 0px'
}

const container = {
    width: '100%',
    padding: '12px 48px',
    display: 'flex',
    flexDirection: 'column'
}

const rightAlign = {
    textAlign: 'right'
}
  
const leftAlign = {
    textAlign: 'left',
    fontWeight: 'bold'
}
  
    return (  
        <div className="container" >
            <div className={'admin-container'}>
                <h1 className="upcase bold alignment">Active Users</h1>
                <div>
                    <div className="usersContainer">
                        {activeUsers && activeUsers.map((user) => (
                        
                            <div className="usertable">
                                <div style={tdStyle}>
                                    <div className="usercard" id={user._id}>
                                        <div>
                                            <p style={rightAlign}>Username:</p>
                                            <p style={rightAlign}>Email:</p>
                                            <p style={rightAlign}>First Name:</p>
                                            <p style={rightAlign}>Last Name:</p>
                                        </div>
                                        <div style={{width: '66%', textOverflow: "ellipsis"}}>
                                            <p style={leftAlign}>{user.name}</p>
                                            <p style={leftAlign}>{user.email}</p>
                                            <p style={leftAlign}>{user.fname}</p>
                                            <p style={leftAlign}>{user.lname}</p>
                                        </div>
                                        <div></div>
                                        <div class="responsive">
                                            <button name="email" onClick={event => checkEmail(event, user.email)}  className="button medium primary-bgcolor" email={user.email}>Deactivate User</button>
                                            <Link to={"/impersonate/" + user._id}><button className="button medium primary-bgcolor">Impersonate Users</button></Link>
                                        </div>
                                        
                                    </div>    
                                </div>
                                {/* <td className="item-container" id={user._id}> */}
                            </div>
                        
                        ))}

                    </div>
                </div>
            {/* <button onclick={getUsers()}>Grab Active Users</button> */}
            </div> 

            <div className={'admin-container'}>
                <h1 className="upcase bold alignment">Inactive Users</h1>
                <div className= "active">
                    <div className="usersContainer">
                        {inactiveUsers && inactiveUsers.map((user) => (
                        
                            <div className="usertable">
                                <div style={tdStyle}>
                                    <div className="usercard" id={user._id}>
                                        <div>
                                            <p style={rightAlign}>Username:</p>
                                            <p style={rightAlign}>Email:</p>
                                            <p style={rightAlign}>First Name:</p>
                                            <p style={rightAlign}>Last Name:</p>
                                        </div>
                                        <div>
                                            <p style={leftAlign}>{user.name}</p>
                                            <p style={leftAlign}>{user.email}</p>
                                            <p style={leftAlign}>{user.fname}</p>
                                            <p style={leftAlign}>{user.lname}</p>
                                        </div>
                                        <div></div>
                                        <div>
                                            <button name="email" onClick={event => activateUser(event, user.email)} className="button medium primary-bgcolor" email={user.email}>Activate User</button>
                                            <Link to={"/impersonate/" + user._id}><button className="button medium primary-bgcolor">Impersonate Users</button></Link>
                                        </div>
                                        
                                    </div>    
                                </div>
                                {/* <td className="item-container" id={user._id}> */}
                            </div>
                        
                        ))}

                    </div>
                </div>
            {/* <button onclick={getUsers()}>Grab Active Users</button> */}
            </div>                   
        </div>
    )
}

export default ActivateUsers