import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { postLoginUser } from '../../actions/auth';

import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'


const LoginForm = props => {

    const [credentials, setCredentials] = useState({})
    //console.log("credentials: " + JSON.stringify(credentials,null,2))
 
    const isLoggedIn = useSelector(state => state.isLoggedIn)
   
    const dispatch = useDispatch()
    const navigate = useNavigate()
 

    const handleChange = e => {
        // Convert the email value to lowercase before updating the state
        if (e.target.name === 'email') {
            setCredentials({ ...credentials, [e.target.name]: e.target.value.toLowerCase() })
        } else {
            setCredentials({ ...credentials, [e.target.name]: e.target.value})
        }
    }

    const loginUser = e => {
        e.preventDefault()
        dispatch(postLoginUser({ props, credentials }))
    };   
    
    const routeChange = () => { 
        let path = `/register`; 
        navigate(path);
    }

    const passwordChange = () => { 
        let path = `/forgot`; 
        navigate(path);
    }

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/home')
        }
    },[isLoggedIn, navigate])


    const backGroundStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%'
    }

    const loginPaperStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        width: '70%',
        borderRadius: '24px',
        height: 'auto',
        paddingTop: '4vh',
        paddingBottom: '4vh',
        margin: '0 auto 256px auto'
    }
    
    const loginEmailStyle = {
        width: '65%',
        height: 'auto',
        backgroundColor: 'white',
        borderRadius: '48px',
        fontStyle: 'italic',
        border: 'none',
        borderImage: 'none',
        textTransform: 'lowercase' // Not needed for forcing lowercase input
    }
    const loginTextFieldStyle = {
        width: '65%',
        height: 'auto',
        backgroundColor: 'white',
        borderRadius: '48px',
        fontStyle: 'italic',
        border: 'none',
        borderImage: 'none'   
    }
    
    const loginFormStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginTop: 1
    }

    const forgotButtonStyle = {
        background: 'none',
        border: 'none',
        fontStyle: 'italic',
        padding: '16px 32px',
        margin: '4px',
        color: 'white'
    }
   
    
    return (
        <div className="login-container" style={backGroundStyle}>
           <Container component="main" maxwidth="xs">
                <CssBaseline />
                <Paper style={loginPaperStyle}>
                    <form style={loginFormStyle} noValidate onSubmit={(e) => loginUser(e)}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        id="email"
                        name="email"
                        placeholder="email"
                        autoComplete="email"
                        autoFocus
                        onChange={handleChange}
                        style={loginEmailStyle}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        id="password"
                        name="password"
                        placeholder="password"
                        type="password"
                        autoComplete="current-password"
                        onChange={handleChange}
                        style={loginTextFieldStyle}
                    />
                

                    <Button
                        type="submit"
                        variant="contained"
                        class="button medium primary-bgcolor"
                    >
                        LOGIN
                    </Button>

    
                    <Button
                        onClick={routeChange}
                        variant="contained"
                        class="button medium primary-bgcolor"
                    >
                        SIGN UP
                    </Button>

    
                    <button
                    onClick={passwordChange}
                    variant="contained"
                    style={forgotButtonStyle}>
                        Forgot Your Password?
                        </button>
                    </form>

                </Paper>
            </Container>
        </div>
    )
}

export default LoginForm
