import React, {Component, useEffect, useState, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import '../../App.css';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios'

const Impersonate = props => {

    const [response, setResponse] = useState({})
    const [collection, setCollection] = useState({})
    const userData = useParams(); 
    const navigate = useNavigate();
    const [userFName, setUserFName] = useState('Loading'); 
    const [userLName, setUserLName] = useState('Loading'); 
    const active = useSelector(state => state.user.isActive);
    const [responded, setResponded] = useState(false)

    useEffect(() => {
        if(!responded){
            console.log("response: " + JSON.stringify(response.data,null,2))

            getCollection(userData.id);
            getUser(userData.id);
        }
      }, [userData, collection])

    //Styles
    const container = {
        width: '100%', 
        padding: '12px 48px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto'
    }

  
    const getCollection = async (id) => {
        console.log("ID: " + id);
        try {
            const response = await axios.get(`${process.env.REACT_APP_GET_COLLECTION}/${id}`);
            setCollection(response.data);
            setResponded(true)
        } catch (error) {
            console.error('Error while fetching collection:', error);
        }
    };

    function deleteBill(id) {
        {/*Axios Delete request needs work. get a net ERR Failed 503*/}
        window.confirm("Do you wish to delete this bill?");
        axios.delete((`${process.env.REACT_APP_DELETE_BILL}/${id}`))
            .then(response => 
                console.log("Delete Successful: " + response.status))
                getCollection(userData)
            .catch(error => {
                console.log("Delete Failed: " + error);
                alert("Failed To Delete Bill.")
            });
    }

    function updateBill(id) {
        {/*Axios Delete request needs work. get a net ERR Failed 503*/}
        navigate(`/editEntry/${id}`)
    }

    function navigateTo(id){
        navigate(`/singleNote/${id}`)
    }
   
    const getUser = async (id) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_GET_USER_DATA}${id}`);
            setUserFName(response.data.fname)
            setUserLName(response.data.lname)
        } catch (error) {
            console.error('Error while fetching user:', error);
        }
    };
    const tFoot = {
        fontWeight: 'bold'
    }

    
    function returnBody() {
        // Check if collection is empty or loading
        
        if (collection.length != null || collection.length != undefined) {
            if (collection.length === 0) {
                return (
                    <div>
                        No Notes
                    </div>
                );
            } else {
                const groupedByDenomination = {};
                collection.forEach(item => {
                    const denomination = parseFloat(item.denomination.replace(/\$/g, ''));
                    if (!groupedByDenomination[denomination]) {
                        groupedByDenomination[denomination] = [];
                    }
                    groupedByDenomination[denomination].push(item);
                });
            
                // Render a table for each group
                const tables = Object.keys(groupedByDenomination).map((denomination, index) => {
                    const itemsInGroup = groupedByDenomination[denomination];
                    let totalDenomination = 0;
                    let totalPurchasePrice = 0;
                    let totalSuggestedSale = 0;
                    let totalActualSale = 0;
            
                    return (
                        <div key={index}>
                            <h2 class="bold upcase primary-color underline">Denomination: ${denomination}</h2>
                            <table className="collectionTable">
                                <thead>
                                    <tr>
                                        <th id="seal-type">Seal Type</th>
                                        <th id="grade">Grade</th>
                                        <th id="serial-number">Serial Number</th>
                                        <th className="hide">Acq Price</th>
                                        <th id="estimated-value">Est Val</th>
                                        <th className="hide">Actual Sale</th>
                                        <th className="hide">Series</th>
                                        <th className="hide">Note Position</th>
                                        <th className="hide">Front Plate</th>
                                        <th className="hide">Back Plate</th>
                                        <th className="hide">Fed Res Loc</th>
                                        <th className="hide">FW?</th>
                                        <th className="hide">Notes</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                    {itemsInGroup.map((item, index) => {
                                        totalDenomination += parseFloat(item.denomination);
                                        totalPurchasePrice += parseFloat(item.purchasePrice);
                                        totalSuggestedSale += parseFloat(item.suggestedSale);
                                        totalActualSale += parseFloat(item.actualSale);
                                        return (
                                            <tbody>
                                            <tr class="hide-headers headers">
                                                <th id="seal-type">Seal Type</th>
                                                <th id="grade">Grade</th>
                                                <th id="serial-number">Serial Number</th>
                                                <th className="hide">Acq Price</th>
                                                <th id="estimated-value">Est Val</th>
                                                <th className="hide">Actual Sale</th>
                                                <th className="hide">Series</th>
                                                <th className="hide">Note Position</th>
                                                <th className="hide">Front Plate</th>
                                                <th className="hide">Back Plate</th>
                                                <th className="hide">Fed Res Loc</th>
                                                <th className="hide">FW?</th>
                                                <th className="hide">Notes</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                            <tr class="data" onClick={() => navigateTo(item._id)} key={index}>
                                                <td>{item.sealType}</td>
                                                <td>{item.grade}</td>
                                                <td>{item.serialNumber}</td>
                                                <td className="hide">${item.purchasePrice}</td>
                                                <td>${item.suggestedSale}</td>
                                                <td className="hide">${item.actualSale}</td>
                                                <td className="hide">{item.series}</td>
                                                <td className="hide">{item.notePosition}</td>
                                                <td className="hide">{item.plateSerial}</td>
                                                <td className="hide">{item.backPlateSerial}</td>
                                                <td className="hide">{item.fedReserveLocation}</td>
                                                <td className="hide">{checkTrue(item.fw)}</td>
                                                <td className="hide">{item.notes}</td>
                                                <td className="actions">
                                                    <button className="button small primary-bgcolor" onClick={(e) => { e.stopPropagation(); updateBill(item._id); }}>Update</button>
                                                    <button className="button small secondary-bgcolor" onClick={(e) => { e.stopPropagation(); deleteBill(item._id); }}>Delete</button>
                                                </td>
                                            </tr>
                                            </tbody>

                                        );
                                    })}
                                <tfoot style={tFoot}>
                                    <tr>
                                        <td>Total: ${totalDenomination.toFixed(2)}</td>
                                        <td></td>
                                        <td></td>
                                        <td className="hide">${totalPurchasePrice.toFixed(2)}</td>
                                        <td>${totalSuggestedSale.toFixed(2)}</td>
                                        <td className="hide">${totalActualSale.toFixed(2)}</td>
                                        {/* Add empty columns for the rest of the table */}
                                        <td colSpan="10"></td>
                                    </tr>
                                    <tr>
                                        <td># of Notes: {itemsInGroup.length}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    );
                });
            
                return <div>{tables}</div>;
            }
           
        }
        else {
            return <div className="loading-spinner"></div>;
        }
    }

    function checkTrue(item) {
        if (item === true) {
            return "Yes"
        }
        else {
            return "No"
        }
    }
    

    function returnCreate() {
        if(active){
            console.log("active")
            return(
                <div>
                    <Link to={"/createEntry/" + userData.id}><button className="button lrg primary-bgcolor">Create New Note</button></Link>
                </div>
            )
        }
        else {
            return(
                <div>
                    Contact Admin to Activate You. 
                </div>
            )
        }
    }

    //console.log("data: " + JSON.stringify(backendData.users, null, 2))
    return (
        <div className="container">
            <div className="flex-header">
                <h1 className="upcase bold alignment">
                    TOTAL COLLECTION 
                </h1>
                <p>{"Impersonating: " + userFName + " " + userLName}</p>
                {returnCreate()}
            </div>
            <br />
            <br />     
            {returnBody()}      
        </div>
    )
}
export default Impersonate