import React, {Component, useEffect, useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import '../../App.css';
import {useSelector, useDispatch} from 'react-redux';
import img from '../../assets/LootLedger-Logo-REV.png';


const Navbar = props => {

    const [data, setData] = useState(0);
    const active = useSelector(state => state.user.isActive);  

    const admin = useSelector(state => state.user.admin)
    //Styles
    
    const navLi = {
        margin: '12px 12px 12px 12px',
        listStyle: 'none'
    }
    const navA = {
        fontWeight: 'bold',
        textDecoration: 'none',
        color: 'white'
    }

    const logoStyle = {
        float: 'left',
        height: '75%',
        maxHeight: '50px',
        alignItems: 'baseline',
        alignSelf: 'center'
    }
    function returnAdminNav() {
        if (admin) {
            return(
                <ul className="navbar">
                    <li style={navLi}><Link style={navA} to="/activateUsers">Activate Users</Link></li>
                    <li style={navLi}><Link style={navA} to="/dropdowns">Dropdowns</Link></li>
                </ul>
            )
        }
        else {
            return
        }
    }

    return (
        <div className="nav-container">
            <Link style={navA} to="/home"><img  style={logoStyle} src={img} alt="Loot Ledger Logo" /></Link>
            {returnAdminNav()}
        </div>
    )
}
export default Navbar