import { userConstants } from '../actions/userTypes';

export const initialState = {
    isLoggedIn: false,
    isLoggingIn: false,
    loginError: '',
    isRegistered: false,
    isActive: false,
    error:'',
    showError: false,
    showNullError: false,
    msg: '',
    token: '',
    updated: false,
    user: {
        acc_type: '',
        address: '',
        address_2: '',
        company: '',
        email: '',
        isActive: false,
        name: '',
        phone: '',
        role: '',
        title: '',
        _id: '',
    },
}

const reducers = (state = initialState, action) => {
    switch (action.type) {
        case userConstants.LOGIN_START: {
            return {
                ...state,
                isLoggingIn: true
            }
        }
        case userConstants.LOGIN_SUCCESS: {
            //console.log(action)
            return {
                ...state,
                
                user: action.payload.user,
                isLoggingIn: false,
                isLoggedIn: true,
                token: action.payload.token
            }
        }
        case userConstants.LOGIN_FAIL: {
            return {
                ...state,
                isLoggingIn: false,
                loginError: action.payload
            }
        }
        case userConstants.UPDATE_TOKEN: {
            return {
                ...state,
                token: action.payload.token
            }
        }
        case userConstants.LOGOUT_USER: {
            return {
                ...state,
                isLoggedIn: false
            }
        }
        case userConstants.REGISTER_SUCCESS: {
            return {
                ...state,
                isLoggingIn: false,
                isLoggedIn: false,
                isRegistered: true,
                token: action.payload
            }
        }
        case userConstants.FORGOT_PASSWORD_SUCCESS: {
            return {
                ...state,
                msg: action.payload,
                showError: false,
                showNullError: false,

            }
        }

        case userConstants.FORGOT_PASSWORD_FAILURE:{
            return {
                ...state, 
                error: action.payload,
                showError: true,
                
            }
        }

        case userConstants.RESET_PASSWORD_START:{
            return {
                ...state,
                updated: false
            }
        }

        case userConstants.RESET_PASSWORD_FAILURE:{
            return {
                ...state, 
                error: action.payload
            }
        }

        case userConstants.UPDATE_PASSWORD_SUCCESS:{
            return {
                ...state,
                updated: true,
                error: false
            }
        }

        case userConstants.UPDATE_PASSWORD_FAILURE:{
            return {
                ...state,
                error: action.payload
            }
        }

        
    default: {
        return state
    }
    }
}

export default reducers