export const userConstants = {
LOGIN_START: 'LOGIN_START',
LOGIN_SUCCESS: 'LOGIN_SUCCESS',
LOGIN_FAIL: 'LOGIN_FAIL',


UPDATE_TOKEN: 'UPDATE_TOKEN',
LOGOUT_USER: 'LOGOUT_USER',

REGISTER_START: 'REGISTER_START',
REGISTER_SUCCESS: 'REGISTER_SUCCESS',
REGISTER_FAIL: 'REGISTER_FAIL',

UPDATE_USER_START: 'UPDATE_USER_START',
UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
UPDATE_USER_FAIL: 'UPDATE_USER_FAIL',

FORGOT_PASSWORD_START: 'FORGOT_PASSWORD_START',
FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',

RESET_PASSWORD_START: 'RESET_PASSWORD_START',
RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

UPDATE_PASSWORD_START: 'UPDATE_PASSWORD_START',
UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_FAILURE',

FETCH_ACTIVE_USERS: 'FETCH_ACTIVE_USERS',
FETCH_ACTIVE_SUCCESS: 'FETCH_ACTIVE_SUCCESS',
FETCH_ACTIVE_FAIL: 'FETCH_ACTIVE_FAIL',

FETCH_INACTIVE_USERS: 'FETCH_INACTIVE_USERS',
FETCH_INACTIVE_SUCCESS: 'FETCH_INACTIVE_SUCCESS',
FETCH_ACTIVE_FAIL: 'FETCH_ACTIVE_FAIL',

ACTIVATE_USER_START: 'ACTIVATE_USER_START',
ACTIVATE_USER_SUCCESS: 'ACTIVATE_USER_SUCCESS',
ACTIVATE_USER_FAIL: 'ACTIVATE_USER_FAIL',

DEACTIVATE_USER_START: 'DEACTIVATE_USER_START',
DEACTIVATE_USER_SUCCESS: 'DEACTIVATE_USER_SUCCESS',
DEACTIVATE_USER_FAIL: 'DEACTIVATE_USER_FAIL'

}
