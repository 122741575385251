import React, {Component, useEffect, useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import '../../App.css';
import {useSelector, useDispatch} from 'react-redux';
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom';


const CreateEntry = props => {

    const [data, setData] = useState(0);
    const params = useParams();
    const loginID = useSelector(state => state.user._id); 
    const [dropdownsResponded, setDropdownsResponded] = useState(false)
    const [dropdowns, setDropdowns] = useState({

    })
    function getID () {
        if(!params.id) {
            return loginID;
        }
        else {
            return params.id
        }
    }

    const [bill, setBill] = useState({
        userId: getID(),
        denomination: '1',
        sealType: '',
        grade: '',
        serialNumber: '',
        purchasePrice: 1,
        suggestedSale: 1,
        actualSale: 1,
        series: '',
        notePosition: '',
        plateSerial: '',
        backPlateSerial: '',
        fedReserveLocation: '',
        fedReserveDistrict: '',
        fw: false,
        notes: ''
    })


    useEffect(() => {
        if (!dropdownsResponded) {
            getDropdowns()
        }
    }, [])

    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        setBill({ ...bill, [name]: newValue });
    };

    const newBill = e => {
        e.preventDefault()

        axios.post(`${process.env.REACT_APP_CREATE_BILL}`, bill)
        .then(response => console.log(response))
        .catch(error => {
            console.error('There was an error!', error);
        });
        navigate('/home');
    }

    function returnPCGSOptions() {
        let options = [];
        for (let i = 1; i < 71; i++) {
            options.push(<option key={i} value={i}>{i}</option>);
        }
        return options;
    }

    const getDropdowns = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_GET_DROPDOWNS}/${"662ed9e118e0c1b0ead39104"}`);
            setDropdowns(response.data[0]);
            setDropdownsResponded(true)
        } catch (error) {
            console.error('Error while fetching dropdowns:', error);
        }
    };

    function returnPCGSOptions() {
        
        let options = [];
        for (let i = 1; i < 71; i++) {
            options.push(<option key={i} value={i}>{i}</option>);
        }
        return options;
    }

    
    function returnDenominations() {
        if(dropdownsResponded){
            const options = dropdowns["denominations"].map((val, i) => (
                <option key={i} value={val}>{val}</option>
            ));
            return options;
        }
        else {
            return (
                <option disabled>Loading</option>
            )
        }
    }

    function returnSeriesOptions() {
        if(dropdownsResponded){
            const options = dropdowns["series"].map((val, i) => (
                <option key={i} value={val}>{val}</option>
            ));
            return options;
        }
        else {
            return (
                <option disabled>Loading</option>
            )
        }
    }

    function returnFedOptions() {
        if(dropdownsResponded){
            const options = dropdowns["fedReserveLocation"].map((val, i) => (
                <option key={i} value={val}>{val}</option>
            ));
            return options;
        }
        else {
            return (
                <option disabled>Loading</option>
            )
        }
    }

    function returnSealTypes() {
        if(dropdownsResponded){
            const options = dropdowns["sealTypes"].map((val, i) => (
                <option key={i} value={val}>{val}</option>
            ));
            return options;
        }
        else {
            return (
                <option disabled>Loading</option>
            )
        }
    }

    //Styles
    const inputStyle = {
        margin: '4px 0',
    }
    const labelStyle = {
        margin: '4px 0',
    }
    const fw = {
        alignItems: 'center',
        textAlign: 'center'
    }
    const submitButton = {
        alignItems: 'center',
        textAlign: 'center',
        alignSelf: 'center'
    }
    const alignSelf = {
        alignSelf: 'center'
    }

    return (

        <div className="container">
            <h1 className="upcase bold alignment">
                Create New Entry
            </h1>
            <form onSubmit={newBill} className="note-form">
                <h3>Note Identifiers</h3>
                <div className="flex-dir">
                    <label style={labelStyle} htmlFor="denomination">Denomination:</label>
                    <select value={bill.denomination} onChange={handleInputChange} style={inputStyle} name="denomination" id="denomination">
                        <option value="">--</option>
                        {returnDenominations()}
                    </select>
                </div>
                <div className="flex-dir">
                    <label style={labelStyle} htmlFor="series">Series:</label>
                    <select value={bill.series} onChange={handleInputChange} style={inputStyle} name="series" id="series">
                        <option value="">--</option>

                        {returnSeriesOptions()}
                    </select>
                </div>
                <div className="flex-dir">
                    <label style={labelStyle} htmlFor="fedReserveLocation">Federal Reserve Location:</label>
                    <select value={bill.fedReserveLocation} onChange={handleInputChange} style={inputStyle} name="fedReserveLocation" id="fedReserveLocation">
                        <option value="">--</option>
                        {returnFedOptions()}
                    </select>
                </div>
                <h3>Note Artifacts</h3>
                <div className="flex-dir">
                    <label style={labelStyle} htmlFor="sealType">Seal Type:</label>
                    <select value={bill.sealType} onChange={handleInputChange} style={inputStyle} name="sealType" id="sealType">
                        <option value="">--</option>
                        {returnSealTypes()}
                    </select>
                </div>
                <div className="flex-dir">
                    <label style={labelStyle} htmlFor="serialNumber">Serial Number:</label>
                    <input value={bill.serialNumber} onChange={handleInputChange} style={inputStyle} name="serialNumber" type="text" />
                </div>
                <div className="flex-dir">
                    <label style={labelStyle} htmlFor="notePosition">Note Position:</label>
                    <input value={bill.notePosition} onChange={handleInputChange} style={inputStyle} name="notePosition" type="text" />
                </div>

                <h3>Note Details</h3>
                <div className="flex-dir">
                    <label style={labelStyle} htmlFor="plateSerial">Front Plate:</label>
                    <input value={bill.plateSerial} onChange={handleInputChange} style={inputStyle} name="plateSerial" type="text" />
                </div>
                <div style={fw} className="flex-dir">
                    <label style={labelStyle} htmlFor="fw">FW?:</label>
                    <input value={bill.fw} onChange={handleInputChange} style={inputStyle} name="fw" type="checkbox" />
                </div>
                <div className="flex-dir">
                    <label style={labelStyle} htmlFor="backPlateSerial">Back Plate:</label>
                    <input value={bill.backPlateSerial} onChange={handleInputChange} tyle={inputStyle} name="backPlateSerial" type="text" />
                </div>

                <h3>Note Providence</h3>
                <div className="flex-dir">
                    <label style={labelStyle} htmlFor="grade">PCGS Grade:</label>
                    <select value={bill.grade} onChange={handleInputChange} style={inputStyle} name="grade" id="grade">
                        <option value="Not Graded">Not Graded</option>
                        {returnPCGSOptions()}
                    </select>
                </div>
                <div className="flex-dir">
                    <label style={labelStyle} htmlFor="purchasePrice">Acquisition Price:</label>
                    <input value={bill.purchasePrice} onChange={handleInputChange} style={inputStyle} name="purchasePrice" type="number" />
                </div>
                
                <div className="flex-dir">
                    <label style={labelStyle} htmlFor="suggestedSale">Estimated Value:</label>
                    <input value={bill.suggestedSale} onChange={handleInputChange} style={inputStyle} name="suggestedSale" type="number" />
                </div>
                
                <div className="flex-dir">
                    <label style={labelStyle} htmlFor="actualSale">Actual Sale:</label>
                    <input value={bill.actualSale} onChange={handleInputChange} style={inputStyle} name="actualSale" type="number" />
                </div>
                
                <div className="flex-dir">
                    <label style={labelStyle} htmlFor="notes">Notes:</label>
                    <textarea value={bill.notes} onChange={handleInputChange} style={inputStyle} name="notes" id="notes" cols="30" rows="10"></textarea>
                </div>

                <div style={alignSelf} className="flex-dir">
                    <button style={submitButton} className="button lrg primary-bgcolor" type="submit">Create Entry</button>
                </div>
            </form>
        </div>
    )
}
export default CreateEntry