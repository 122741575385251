import React from 'react';
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom';


  export default function Logout () {
    const navigate = useNavigate()
    const useToggle = (initialState) => {
        const [isToggled, setIsToggled] = React.useState(initialState);
      
        const toggle = React.useCallback(
          () => setIsToggled(state => !state),
          [setIsToggled],
        );
      
        return [isToggled, toggle];
      }
      

    localStorage.removeItem('token')
    useToggle();
    navigate('/login')
    // <Redirect to="/" replace state={{from: location}} />
    
   
} 