import axios from 'axios';
import { userConstants } from'../actions/userTypes';


export const postLoginUser = payload => dispatch => {
    dispatch({ type: userConstants.LOGIN_START })
    axios
    .post(`${process.env.REACT_APP_LOGIN}`, payload.credentials)
    .then(res => {
        dispatch({ type: userConstants.LOGIN_SUCCESS, payload: res.data });
        // localStorage.setItem('user', payload.user);
        return res.data; // pass the user data down to the promise chain
    })
    
    .catch(err => {
        alert("Incorrect Credentials.")
        dispatch({ type: userConstants.LOGIN_FAIL, payload:err })
    });
};


export const postForgotPassword = payload => dispatch =>{
    dispatch({type: userConstants.FORGOT_PASSWORD_START, payload: payload })
    axios
    .post(`${process.env.REACT_APP_FORGOT_PASS}`, payload.credentials)
    .then(res =>{
        if(res === null) {
            alert("No email found.")
            dispatch({ type: userConstants.FORGOT_PASSWORD_SUCCESS, payload: res.data });
        }
        else {
            alert('email sent')
            dispatch({ type: userConstants.FORGOT_PASSWORD_SUCCESS, payload: res.data });
        }
    })
    .catch(err => {
        alert('Check your email for a password link.')
        dispatch({ type: userConstants.FORGOT_PASSWORD_FAILURE, payload: err})
    }
    )
}


export const postRegisterUser = payload => dispatch => {
    dispatch({ type: userConstants.REGISTER_START })
    axios
    .post(`${process.env.REACT_APP_REGISTER}`, payload.credentials)
    .then(res => {
        dispatch({ type: userConstants.REGISTER_SUCCESS, payload: payload });
        alert("Sign up successful.")
    })
    .catch(err => dispatch({ type: userConstants.REGISTER_FAIL, payload:err }));
  
};


export const putUpdateUser = payload => dispatch => {
    dispatch({ type: userConstants.REGISTER_START })
    axios
    .put(`${process.env.REACT_APP_UP_USER}`, payload.credentials)
    .then(res => {
        dispatch({ type: userConstants.UPDATE_USER_SUCCESS, payload: payload });
    })
    .catch(err => dispatch({ type: userConstants.UPDATE_USER_FAIL, payload:err }));


};


export const updateToken = (payload) => {
    return {
      type: userConstants.UPDATE_TOKEN,
      payload: payload
    };
  };


export const logoutUser = payload => dispatch => {
    dispatch({ type: userConstants.LOGOUT_USER, payload: payload });
    payload.history.push('/login');
}



export const getActiveUsers = () => dispatch => {
    //console.log('dispatch?')
    dispatch({ type: userConstants.FETCH_ACTIVE_USERS })
        axios.get(`${process.env.REACT_APP_ACTIVE_USR}`)
            .then(res =>{
                //console.log(res.data, '<- Data in fetch dispatch')
                dispatch({
                    type: userConstants.FETCH_ACTIVE_SUCCESS,
                    payload: res.data
                })
            })
            .catch(err => {
               
                dispatch({
                    type: userConstants.FETCH_ACTIVE_FAIL,
                    error: err.response.data.message
                })
            })
}


export const getInactiveUsers = () => dispatch => {
    //console.log('dispatch?')
    dispatch({ type: userConstants.FETCH_INACTIVE_USERS })
        axios.get(`${process.env.REACT_APP_INACTIVE_USR}`)
            .then(res =>{
                //console.log(res.data)
                dispatch({
                    type: userConstants.FETCH_INACTIVE_SUCCESS,
                    payload: res.data
                })
            })
            .catch(err => {
               
                dispatch({
                    type: userConstants.FETCH_INACTIVE_FAIL,
                    error: err.response.data.message
                })
            })
}


export const activateUser = payload => dispatch => {
    dispatch({ type: userConstants.ACTIVATE_USER_START });
    axios
        .put(`${process.env.REACT_APP_ACTIVE_USR}`, payload.email)
        .then(res => {
            dispatch({ type: userConstants.ACTIVATE_USER_SUCCESS, payload: payload });
        })
        .catch(err => {
            dispatch({ type: userConstants.ACTIVATE_USER_FAIL, payload: err });
        });
};



export const deactivateUser = payload => dispatch => {
    dispatch({ type: userConstants.DEACTIVATE_USER_START })
    axios
    .put(`${process.env.REACT_APP_DEACTIVATE}`, payload.email)
    .then(res => {
        dispatch({ type: userConstants.DEACTIVATE_USER_SUCCESS, payload: res.data });
    })
    .catch(err => 
        dispatch({
             type: userConstants.DEACTIVATE_USER_FAIL, 
             payload: err }));
   
};


export const putUpdatePassword = payload => dispatch => {
    dispatch({ type: userConstants.UPDATE_PASSWORD_START })
    axios
    .put(`${process.env.REACT_APP_UPDATE_PASS}`, payload.credentials)
    .then(res => {
        alert('Your password has been successfully updated.')
        dispatch({ type: userConstants.UPDATE_PASSWORD_SUCCESS, payload: payload })
    })
    .catch(err => {
        alert('Failed updating your password')        
        dispatch({ type: userConstants.UPDATE_PASSWORD_FAILURE, payload:err })
    });
};