import React from 'react'
import { useLocation } from 'react-router'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
	

	const useAuth = () => {
        // return useSelector(state => state.isLoggedIn)
		return useSelector(state => state.token)
	}
	

	const ProtectedRoutes = (props) =>{
	    const authorized = useAuth()
		const location = useLocation()
		return authorized ? <Outlet/> : <Navigate to="/login" replace state={{from: location}} />
		// const cat = localStorage.getItem('token');

	
		// 	if(cat !== ""){
		// 		return <Outlet/>
		// 	}
        // return <Navigate to="/login" replace state={{from: location}} />
    }
	
	export default ProtectedRoutes;