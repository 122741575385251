import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

const NoteUpload = () => {
    const [frontNoteImage, setFrontNoteImage] = useState(null);
    const [backNoteImage, setBackNoteImage] = useState(null);
    const [error, setError] = useState('');
    const [uploading, setUploading] = useState(false);
    const userData = useSelector(state => state.user._id)

    const getPresignedUrl = async (fileName) => {
        try {
            const response = await axios.post(process.env.REACT_APP_GENERATE_PRESIGNED_URL, { fileName });
            return response.data.url;
        } catch (error) {
            console.error("Error generating presigned URL", error);
            setError('Error generating upload URL. Please try again.');
            throw error;
        }
    };

    const uploadFile = async (file, url) => {
        try {
            await axios.put(url, file, {
                headers: {
                    'Content-Type': file.type
                }
            });
            console.log("File uploaded successfully");
        } catch (error) {
            console.error("Error uploading file", error);
            setError('Error uploading file. Please try again.');
            throw error;
        }
    };

    const handleFrontImageChange = (e) => {
        const file = e.target.files[0];
        setFrontNoteImage(file);
        setError('');
    };

    const handleBackImageChange = (e) => {
        const file = e.target.files[0];
        setBackNoteImage(file);
        setError('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!frontNoteImage || !backNoteImage) {
            setError('Both front and back note images are required.');
            return;
        }
        setUploading(true);
        try {
            const frontPresignedUrl = await getPresignedUrl(`front-${Date.now()}-${frontNoteImage.name}`);
            const backPresignedUrl = await getPresignedUrl(`back-${Date.now()}-${backNoteImage.name}`);
            console.log("front: " + JSON.stringify(frontPresignedUrl, null, 2))
            console.log("back: " + JSON.stringify(backPresignedUrl, null, 2))

            await uploadFile(frontNoteImage, frontPresignedUrl);
            await uploadFile(backNoteImage, backPresignedUrl);

            console.log('Front image uploaded to:', frontPresignedUrl);
            console.log('Back image uploaded to:', backPresignedUrl);
            setError('');
        } catch (error) {
            console.error('Error uploading note images:', error);
        } finally {
            setUploading(false);
        }
    };

    function returnInputs() {
        if (userData === "6600bc90f22a856220fb0850") {
            return(
                <div>
                    <h1>Upload Note Images</h1>
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        {uploading && <p>Uploading...</p>}
                    <div>
                        <label htmlFor="frontNoteImage">Front of Note:</label>
                        <input type="file" name="frontNoteImage" onChange={handleFrontImageChange} required />
                    </div>
                    <div>
                        <label htmlFor="backNoteImage">Back of Note:</label>
                        <input type="file" name="backNoteImage" onChange={handleBackImageChange} required />
                    </div>
                    <button type="submit" onClick={(handleSubmit)} disabled={uploading}>Upload Note</button>
                </div>
            )
        }
        else {
            return(<div></div>)
        }
    }

    return (
        returnInputs()
    );
};

export default NoteUpload;
