import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../../App.css';
import axios from 'axios';
import NoteUpload from './noteUpload.jsx'; // Importing the NoteUpload component

const EditEntry = (props) => {
    const [responded, setResponded] = useState(false);
    const [dropdownsResponded, setDropdownsResponded] = useState(false);
    const [dropdowns, setDropdowns] = useState({});
    const [bill, setBill] = useState({});
    const [selectedFrontFile, setSelectedFrontFile] = useState(null); // New state for front file
    const [selectedBackFile, setSelectedBackFile] = useState(null); // New state for back file

    const billId = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!responded) {
            getBill(billId);
        }
        if (!dropdownsResponded) {
            getDropdowns();
        }
    }, [responded, dropdownsResponded, billId]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        setBill({ ...bill, [name]: newValue });
    };

    const handleFrontFileChange = (file) => {
        setSelectedFrontFile(file);
    };

    const handleBackFileChange = (file) => {
        setSelectedBackFile(file);
    };

    const updateBill = (id) => {
        const formData = new FormData();
        for (const key in bill) {
            formData.append(key, bill[key]);
        }
        if (selectedFrontFile) {
            formData.append('frontFile', selectedFrontFile);
        }
        if (selectedBackFile) {
            formData.append('backFile', selectedBackFile);
        }

        axios.post(`${process.env.REACT_APP_UPDATE_BILL}/${id.id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
            console.log("Update Successful: " + response.status);
        })
        .catch(error => {
            console.log("Update Failed: " + error);
        });
        navigate('/home');
    };

    const getBill = async (id) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_GET_BILL}/${id.id}`);
            setBill(response.data[0]);
            setResponded(true);
        } catch (error) {
            console.error('Error while fetching collection:', error);
        }
    };

    const getDropdowns = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_GET_DROPDOWNS}/${"662ed9e118e0c1b0ead39104"}`);
            setDropdowns(response.data[0]);
            setDropdownsResponded(true);
        } catch (error) {
            console.error('Error while fetching dropdowns:', error);
        }
    };

    function returnDenominations() {
        if (dropdownsResponded) {
            return dropdowns["denominations"].map((val, i) => (
                <option key={i} value={val}>{val}</option>
            ));
        } else {
            return <option disabled>Loading</option>;
        }
    }

    function returnPCGSOptions() {
        let options = [];
        for (let i = 1; i < 71; i++) {
            options.push(<option key={i} value={i}>{i}</option>);
        }
        return options;
    }

    function returnSeriesOptions() {
        if (dropdownsResponded) {
            return dropdowns["series"].map((val, i) => (
                <option key={i} value={val}>{val}</option>
            ));
        } else {
            return <option disabled>Loading</option>;
        }
    }

    function returnFedOptions() {
        if (dropdownsResponded) {
            return dropdowns["fedReserveLocation"].map((val, i) => (
                <option key={i} value={val}>{val}</option>
            ));
        } else {
            return <option disabled>Loading</option>;
        }
    }

    function returnSealTypes() {
        if (dropdownsResponded) {
            return dropdowns["sealTypes"].map((val, i) => (
                <option key={i} value={val}>{val}</option>
            ));
        } else {
            return <option disabled>Loading</option>;
        }
    }

    const inputStyle = { margin: '4px 0' };
    const labelStyle = { margin: '4px 0' };
    const fw = { alignItems: 'center', textAlign: 'center' };
    const submitButton = { alignItems: 'center', textAlign: 'center', alignSelf: 'center' };
    const alignSelf = { alignSelf: 'center' };

    function returnEdit() {
        if (bill !== undefined) {
            return (
                <form onSubmit={(e) => { e.preventDefault(); updateBill(billId); }} className="note-form">
                    <h3>Note Identifiers</h3>
                    <div className="flex-dir">
                        <label style={labelStyle} htmlFor="denomination">Denomination:</label>
                        <select value={bill.denomination || ""} onChange={handleInputChange} style={inputStyle} name="denomination" id="denomination">
                            <option value="">--</option>
                            {returnDenominations()}
                        </select>
                    </div>
                    <div className="flex-dir">
                        <label style={labelStyle} htmlFor="series">Series:</label>
                        <select value={bill.series || ""} onChange={handleInputChange} style={inputStyle} name="series" id="series">
                            <option value="">--</option>
                            {returnSeriesOptions()}
                        </select>
                    </div>
                    <div className="flex-dir">
                        <label style={labelStyle} htmlFor="fedReserveLocation">Federal Reserve Location:</label>
                        <select value={bill.fedReserveLocation || ""} onChange={handleInputChange} style={inputStyle} name="fedReserveLocation" id="fedReserveLocation">
                            <option value="">--</option>
                            {returnFedOptions()}
                        </select>
                    </div>
                    <h3>Note Artifacts</h3>
                    <div className="flex-dir">
                        <label style={labelStyle} htmlFor="sealType">Seal Type:</label>
                        <select value={bill.sealType || ""} onChange={handleInputChange} style={inputStyle} name="sealType" id="sealType">
                            <option value="">--</option>
                            {returnSealTypes()}
                        </select>
                    </div>
                    <div className="flex-dir">
                        <label style={labelStyle} htmlFor="serialNumber">Serial Number:</label>
                        <input value={bill.serialNumber || ""} onChange={handleInputChange} style={inputStyle} name="serialNumber" type="text" />
                    </div>
                    <div className="flex-dir">
                       
                    <label style={labelStyle} htmlFor="notePosition">Note Position:</label>
<input value={bill.notePosition || ""} onChange={handleInputChange} style={inputStyle} name="notePosition" type="text" />
</div>
<h3>Note Details</h3>
<div className="flex-dir">
    <label style={labelStyle} htmlFor="plateSerial">Front Plate:</label>
    <input value={bill.plateSerial || ""} onChange={handleInputChange} style={inputStyle} name="plateSerial" type="text" />
</div>
<div style={fw} className="flex-dir">
    <label style={labelStyle} htmlFor="fw">FW?:</label>
    <input value={bill.fw || ""} onChange={handleInputChange} style={inputStyle} name="fw" type="checkbox" />
</div>
<div className="flex-dir">
    <label style={labelStyle} htmlFor="backPlateSerial">Back Plate:</label>
    <input value={bill.backPlateSerial || ""} onChange={handleInputChange} style={inputStyle} name="backPlateSerial" type="text" />
</div>
<h3>Note Providence</h3>
<div className="flex-dir">
    <label style={labelStyle} htmlFor="grade">PCGS Grade:</label>
    <select value={bill.grade || ""} onChange={handleInputChange} style={inputStyle} name="grade" id="grade">
        <option value="Not Graded">Not Graded</option>
        {returnPCGSOptions()}
    </select>
</div>
<div className="flex-dir">
    <label style={labelStyle} htmlFor="purchasePrice">Acquisition Price:</label>
    <input value={bill.purchasePrice || ""} onChange={handleInputChange} style={inputStyle} name="purchasePrice" type="number" />
</div>
<div className="flex-dir">
    <label style={labelStyle} htmlFor="suggestedSale">Estimated Value:</label>
    <input value={bill.suggestedSale || ""} onChange={handleInputChange} style={inputStyle} name="suggestedSale" type="number" />
</div>
<div className="flex-dir">
    <label style={labelStyle} htmlFor="actualSale">Actual Sale:</label>
    <input value={bill.actualSale || ""} onChange={handleInputChange} style={inputStyle} name="actualSale" type="number" />
</div>
<div className="flex-dir">
    <label style={labelStyle} htmlFor="notes">Notes:</label>
    <textarea value={bill.notes || ""} onChange={handleInputChange} style={inputStyle} name="notes" id="notes" cols="30" rows="10"></textarea>
</div>
{/* Incorporate NoteUpload component for uploading front and back images */}
<NoteUpload
    onFrontFileChange={handleFrontFileChange}
    onBackFileChange={handleBackFileChange}
/>
<div style={alignSelf} className="flex-dir">
    <button style={submitButton} className="button lrg primary-bgcolor" type="submit">Update Entry</button>
</div>
</form>
);
} else {
return <div className="loading-spinner"></div>;
}
}

return (
<div className="container">
<h1 className="upcase bold alignment">Update Entry</h1>
{returnEdit()}
</div>
);
};

export default EditEntry;
