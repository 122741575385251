import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom'

import ProtectedRoutes from './utils/protectedRoutes';

import './App.css';

//Actions
import { updateToken } from './actions/auth';

//Components
import LoginForm from './components/login/loginForm';
import RegisterForm from './components/registration/userRegister';
import Home from './components/views/home';
import Navbar from './components/views/navbar';
import CreateEntry from './components/views/createEntry';
import EditEntry from './components/views/editEntry';
import ActivateUsers from './components/views/activateUsers';
import Impersonate from './components/views/impersonate';
import SingleNote from './components/views/singleNote';
import Dropdowns from './components/views/dropdowns';
import ForgotPassword from './components/login/forgotPass';
import ResetPass from './components/login/resetPass';

function App() {
  const dispatch = useDispatch();
  const currToken = useSelector(state => state.token);
  const newToken = localStorage.getItem('token');


  useEffect(() => {
    // If there's a new token in localStorage and the current token is empty, update the token in Redux store
    if (!currToken && newToken) {
      dispatch(updateToken(newToken));
    } else if (currToken) {
      // If there's a current token in Redux store, store it in localStorage
      localStorage.setItem('token', currToken);
    }
  }, [currToken, newToken, dispatch]);

  return (
    <Router>
        <Navbar />

        <Routes>
          <Route path="/" element={<ProtectedRoutes/>}>
            <Route index element={<Navigate replace to="/home" />} />

            <Route path="/home" element={<Home/>} />
            <Route path="/editEntry/:id" element={<EditEntry/>} />
            <Route path="/createEntry" element={<CreateEntry/>} />
            <Route path="/createEntry/:id" element={<CreateEntry/>} />
            <Route path="/singleNote/:id" element={<SingleNote/>} />

            <Route path="/activateUsers" element={<ActivateUsers/>} />
            <Route path="/impersonate/:id" element={<Impersonate/>} />
            <Route path="/dropdowns" element={<Dropdowns/>} />

          </Route>
            
          <Route path="/login" element={<LoginForm/>} />
          <Route path="/register" element={<RegisterForm/>} />
          <Route path="/forgot" element={<ForgotPassword/>} />
          <Route path="/reset/:token" element={<ResetPass/>} />
        </Routes>
    </Router>
  )
};

export default App;