import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';

import { postRegisterUser } from '../../actions/auth';
import { postLoginUser } from '../../actions/auth';


import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import ListItem from '@mui/material/ListItem'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'

const RegisterForm = props => {

    const [credentials, setCredentials] = useState({})
    //const [creds, setCreds] = useState({})
    //console.log("credentials: " + JSON.stringify(credentials,null,2))

    //console.log("creds: " + JSON.stringify(creds,null,2))
    const isRegistered = useSelector(state => state.isRegistered)
    const isLoggedIn = useSelector(state => state.isLoggedIn)
    //const userData = useSelector(state => state.user)
    //console.log("userData: " + JSON.stringify(userData,null,2))

    //console.log("registered?: " + isRegistered)
    //console.log("loggedIn?: " + isLoggedIn)

    const state = useSelector(state => state)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    localStorage.setItem('token', '');
    const [match, setMatch] = useState(false)
    const [validUserName, setValidUserName] = useState(false)
    const [validEmail, setValidEmail] = useState(false)
    //console.log("credentials: " + JSON.stringify(credentials,null,2))

    useEffect(() => {
        if (isRegistered) { 
            loginUser()

            if(isLoggedIn) {
                navigate('/home')
            }
        }
        //const script = document.createElement("script")
        //script.src = "https://www.google.com/recaptcha/api.js?render=6LcKIcMlAAAAAElqn5HEHgswz5totpW0n-zMWXfq"
        //script.addEventListener("load", handleLoaded)
        //document.body.appendChild(script)
        validatePassword()
        validateConfirm()
        validateEmail()
        //console.log("userName: " + JSON.stringify(credentials,null,2))
        //console.log("match: " + match)
    },[isRegistered, isLoggedIn, navigate, match])


    const handleChange = e => {
        setCredentials({ ...credentials, 
            resetPasswordToken: "",
            resetPasswordExpires: new Date,
            [e.target.name]: (e.target.value).trim()})

        validateConfirm()
    }

    const handleConfirm = e => {
        if (e.target.value === document.getElementById('password').value) {
            setMatch(true)
        }
        else {
            setMatch(false)
        }
    }   

    function validateConfirm() {

        if((document.getElementById('password') === null) && (document.getElementById('confirm') === null)) {
            setMatch(false)
            return false
        }
        if((document.getElementById('password') === null) || (document.getElementById('confirm') === null)) {
            setMatch(false)
            return false
        }
        if((document.getElementById('password') === undefined) && (document.getElementById('confirm') === undefined)) {
            setMatch(false)
            return false
        }
        if((document.getElementById('password') === undefined) || (document.getElementById('confirm') === undefined)) {
            setMatch(false)
            return false
        }
        else {
            if (document.getElementById('password').value === document.getElementById('confirm').value) {
                //console.log("document.getElementById('password').value"+document.getElementById('password').value)
                //console.log("document.getElementById('confirm').value"+document.getElementById('confirm').value)

                setMatch(true)
                return true
            }
            if (document.getElementById('password').value != document.getElementById('confirm').value) {
                //console.log("document.getElementById('password').value"+document.getElementById('password').value)
                //console.log("document.getElementById('confirm').value"+document.getElementById('confirm').value)

                setMatch(false)
                return false
            }
            else {
                setMatch(false)
                return false
            }
        }
    }

    function checkUndefined(x) {
        if(x === undefined || x === null || x === "") {
            return "User Did Not Provide."
        }
        else {
            return x
        }
    }

    function validatePassword() {
        if (document.getElementById('password') === null) {
            return false
        }
        else {
            var p = document.getElementById('password').value,
            errors = [];
            if (p.length < 10) {
                errors.push("10 characters. \n");
            }
            if (p.search(/[a-z]/) < 0) {
                errors.push("1 lowercase letter. \n"); 
            }
            if (p.search(/[A-Z]/) < 0) {
                errors.push("1 uppercase letter. \n"); 
            }
            if (p.search(/[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]/i) < 0) {
                errors.push("1 special character. \n"); 
            }
            {/*
            if (p.search(/[0-9]/) < 0) {
                errors.push("1 digit. \n");
            }
            */}
            if (errors.length > 0) {
                return (errors)
            }
        return true
        }
    }

    function validateEmail(){
        if(credentials.email != undefined || credentials.email != null) {
            if(validator.isEmail(credentials.email)) {
                return true
            }
            else {
                return false
            }
        }
        else {
            return false
        }
    }
  
    function validateUserName() {
        if(credentials.name != undefined && credentials.name.length > 0) {
            return true
        }
        else {
            return false
        }
    }

    //console.log("test")
    function allowSignUp() {
        if ((match) && (validatePassword() === true) && validateEmail() && validateUserName()) {
            return loginButtonStyle
        }
        else {
            return loginButtonStyleDisabled
        }
    }

    const registerUser = async (e) => {
        localStorage.setItem('user', '');
        e.preventDefault()
        dispatch(postRegisterUser({ props, credentials }))
        
    }

    function renderPasswordRequirements() {
        if(validatePassword() === undefined) {
            return
        }
        if(validatePassword().length != undefined) {
            return (
                <div style={requirementStyles}>
                    {validatePassword().map((req, i) =>
                        <span style={noPadding} key={i}>{req}</span>
                    )}
                </div>
            )
        }
    }

    const routeChange = () =>{ 
        let path = `/login`; 
        navigate(path);
    }

    const handleLoaded = _ => {
        window.grecaptcha.ready(_ => {
            window.grecaptcha
            .execute("6LcKIcMlAAAAAElqn5HEHgswz5totpW0n-zMWXfq", { action: "register" })
            .then(token => {
                // ...
            })
        })
    }

    const loginUser = () => {
        //e.preventDefault()
        dispatch(postLoginUser({ props, credentials }))
    }; 

    function checkPassword(){
        //console.log("validatePassword(): " + validatePassword())
        if(validatePassword().length > 0) {
            return true
        }
        else{
            return false
        }
    }

    const noPadding = {
        padding: '0 0',
        margin: '0 0'
    }

    const loginPaperStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        width: '70%',
        borderRadius: '24px',
        height: 'auto',
        paddingTop: '4vh',
        paddingBottom: '4vh',
        marginLeft: 'auto',
        marginRight: 'auto'
    }

    const loginTextFieldStyle = {
        width: '65%',
        height: 'auto',
        backgroundColor: 'white',
        borderRadius: '48px',
        fontStyle: 'italic',
        border: 'none',
        borderImage: 'none'  
    }

    const loginFormStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginTop: 1,
    }

    const loginButtonStyle = {
        border: 'none',        
    }

    const loginButtonStyleDisabled = {
        pointerEvents: 'none',
        border: 'none'
    }

    const returnButtonStyle = {
        background: 'none',
        border: 'none',
        fontStyle: 'italic',
        padding: '16px 32px',
        margin: '4px',
        color: 'white'
    }

    const requirementStyles = {
        padding: '0 0',
        margin: '0 0',
        display: 'inline',
        width: '65%',
        height: 'auto',
        textAlign: 'left',
        fontSize: '1.75vh',
        color: '#d32f2f'
    }    

   

    return (
        <Container 
            className="g-recaptcha"
            data-sitekey="6LcKIcMlAAAAAElqn5HEHgswz5totpW0n-zMWXfq"
            data-size="invisible"
            component="main" 
            maxwidth="xs">
            <CssBaseline />
            <Paper style={loginPaperStyle}>
                <form style={loginFormStyle} noValidate onSubmit={(e) => registerUser(e)}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    size="small"
                    required
                    id="name"
                    label="Username"
                    name="name"
                    onChange={handleChange}
                    style={loginTextFieldStyle}
                    error={!validateUserName()}
                />
                    <TextField
                    variant="outlined"
                    margin="normal"
                    size="small"
                    required
                    id="email"
                    label="email"
                    name="email"
                    type="email"
                    onChange={handleChange}
                    style={loginTextFieldStyle}
                    error={!validateEmail()}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    size="small"
                    id="password"
                    label="Password"
                    name="password"
                    type="password"
                    onChange={handleChange}
                    error={checkPassword()}
                    style={loginTextFieldStyle}
                />

                <strong>{renderPasswordRequirements()}</strong>
                
                <TextField
                    variant="outlined"
                    margin="normal"
                    size="small"
                    required
                    id="confirm"
                    label="Confirm Password"
                    name="password"
                    type="password"
                    style={loginTextFieldStyle}
                    onChange={handleConfirm}
                    error={!match}
                />

                    <TextField
                    variant="outlined"
                    margin="normal"
                    size="small"
                    id="fname"
                    required
                    label="First Name"
                    name="fname"
                    onChange={handleChange}
                    style={loginTextFieldStyle}
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    size="small"
                    id="lname"
                    required
                    label="Last Name"
                    name="lname"
                    onChange={handleChange}
                    style={loginTextFieldStyle}
                />
            
                <Button
                    type="submit"
                    variant="contained"
                    style={allowSignUp()}
                    class="button medium primary-bgcolor"

                >
                    Sign Up
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    class="button medium primary-bgcolor"
                    onClick={routeChange}
                >
                    Return to Login
                </Button>
                </form>

            </Paper>
        </Container>

    )
}

export default RegisterForm