import React, {Component, useEffect, useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import '../../App.css';
import {useSelector, useDispatch} from 'react-redux';
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom';


const Dropdowns = props => {

    const [data, setData] = useState(0);
    const params = useParams();
    const loginID = useSelector(state => state.user._id); 
    const [dropdownsResponded, setDropdownsResponded] = useState(false)
    const [dropdowns, setDropdowns] = useState({

    })


    const [newValue, setNewValue] = useState(""); 



    useEffect(() => {
        if (!dropdownsResponded) {
            getDropdowns()
        }
    }, [dropdowns])

    const navigate = useNavigate();

    const getDropdowns = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_GET_DROPDOWNS}/${"662ed9e118e0c1b0ead39104"}`);
            setDropdowns(response.data[0]);
            setDropdownsResponded(true)
        } catch (error) {
            console.error('Error while fetching dropdowns:', error);
        }
    };

    function updateDropdowns() {
        axios.post(`${process.env.REACT_APP_UPDATE_DROPDOWNS}/${"662ed9e118e0c1b0ead39104"}`, dropdowns)
            .then(response => {
                console.log("Update Successful: " + response.status);
                getDropdowns()
            })
            .catch(error => {
                console.log("Update Failed: " + error);
            });
        navigate('/home')
    }
    
    const removeItem = (category, index) => {
        setDropdowns(oldValues => {
          return {
            ...oldValues,
            [category]: oldValues[category].filter((_, i) => i !== index)
          };
        });
        setNewValue("")
    };

    const updateItem = (category, index, newValue) => {
        setDropdowns(oldValues => {
          return {
            ...oldValues,
            [category]: oldValues[category].map((value, i) => {
              if (i === index) {
                return newValue; // Replace the value at the specified index
              }
              return value; // Keep other values unchanged
            })
          };
        });
        setNewValue("")
      };
      const createNewItem = (category, index, newValue) => {
        setDropdowns(oldValues => {
          return {
            ...oldValues,
            [category]: [
              ...oldValues[category].slice(0, index), // Items before the index
              newValue, // New value at the index
              ...oldValues[category].slice(index) // Items after the index
            ]
          };
        });
        setNewValue("")
      };
      const handleInputChange = (event) => {
        setNewValue(event.target.value); // Update the state variable with the input value
      };
      
    function returnDenominations() {
        if(dropdownsResponded){
            const options = dropdowns["denominations"].map((val, i) => (
                <tr className="noHover" style={labelStyle} key={i} value={val}>
                    <td>{val}</td>
                    <td><input type="text" onChange={handleInputChange} /><button style={createButton} onClick={() => createNewItem("denominations", i, newValue)}>Create Before</button></td> 
                    <td><input type="text" onChange={handleInputChange} /><button style={updateButton} onClick={() => updateItem("denominations", i, newValue)}>Update</button></td> 
                    <td><button style={removeButton} onClick={() => removeItem("denominations", i)}>Remove</button></td>
                </tr>
            ));
            return options;
        }
        else {
            return (
                <tr disabled>Loading</tr>
            )
        }
    }

    function returnSeriesOptions() {
        if(dropdownsResponded){
            const options = dropdowns["series"].map((val, i) => (
                <tr className="noHover" style={labelStyle} key={i} value={val}>
                    <td>{val}</td>
                    <td><input type="text" onChange={handleInputChange} /><button style={createButton} onClick={() => createNewItem("series", i, newValue)}>Create Before</button></td> 
                    <td><input type="text" onChange={handleInputChange} /><button style={updateButton}  onClick={() => updateItem("series", i, newValue)}>Update</button></td> 
                    <td><button style={removeButton} onClick={() => removeItem("series", i)}>Remove</button></td>
                </tr>
            ));
            return options;
        }
        else {
            return (
                <tr disabled>Loading</tr>
            )
        }
    }

    function returnFedOptions() {
        if(dropdownsResponded){
            const options = dropdowns["fedReserveLocation"].map((val, i) => (
                <tr className="noHover" style={labelStyle} key={i} value={val}>
                    <td>{val}</td>
                    <td><input type="text" onChange={handleInputChange} /><button style={createButton} onClick={() => createNewItem("fedReserveLocation", i, newValue)}>Create Before</button></td> 
                    <td><input type="text" onChange={handleInputChange} /><button style={updateButton}  onClick={() => updateItem("fedReserveLocation", i, newValue)}>Update</button></td> 
                    <td><button style={removeButton} onClick={() => removeItem("fedReserveLocation", i)}>Remove</button></td>
                </tr>
            ));
            return options;
        }
        else {
            return (
                <tr disabled>Loading</tr>
            )
        }
    }

    function returnSealTypes() {
        if(dropdownsResponded){
            const options = dropdowns["sealTypes"].map((val, i) => (
                <tr className="noHover" style={labelStyle} key={i} value={val}>
                    <td>{val}</td>
                    <td><input type="text" onChange={handleInputChange} /><button style={createButton} onClick={() => createNewItem("sealTypes", i, newValue)}>Create Before</button></td> 
                    <td><input type="text" onChange={handleInputChange} /><button style={updateButton} onClick={() => updateItem("sealTypes", i, newValue)}>Update</button></td> 
                    <td><button style={removeButton} onClick={() => removeItem("sealTypes", i)}>Remove</button></td>
                </tr>
            ));
            return options;
        }
        else {
            return (
                <tr disabled>Loading</tr>
            )
        }
    }

    //Styles
    const inputStyle = {
        margin: '4px 0',
    }
    const labelStyle = {
        margin: '12px 0',
        padding: '12px'
    }
    const fw = {
        alignItems: 'center',
        textAlign: 'center'
    }
    const submitButton = {
        alignItems: 'center',
        textAlign: 'center',
        alignSelf: 'center'
    }
    const alignSelf = {
        alignSelf: 'center'
    }
    const h3Style = {
        color: '#73A478',
        fontSize: '18px',
        fontStyle: 'italic',
        textDecoration: 'underline'
    }
    const updateButton = {
        backgroundColor: "yellow",
        color: "black"
    }
    const removeButton = {
        backgroundColor: "red",
        color: "black"
    }
    const createButton = {
        backgroundColor: "green",
        color: "white"
    }
    return (

        <div className="container">
            <button onClick={()=>updateDropdowns()}>Save Changes</button>
            <h1 className="upcase bold">Dropdowns </h1>
            <table>
                <tr className="noHover"><td style={h3Style}>Denomination: </td></tr>
                {returnDenominations()}
                <tr className="noHover"><br /></tr>
                <tr className="noHover"><td style={h3Style}>Seal Types: </td></tr>
                {returnSealTypes()}
                <tr className="noHover"><br /></tr>
                <tr className="noHover"><td style={h3Style}>Series:</td></tr>
                {returnSeriesOptions()}
                <tr className="noHover"> <br /></tr>
                <tr className="noHover"><td style={h3Style}>Federal Reserve Locations: </td></tr>
                {returnFedOptions()}
            </table>
        </div>
    )
}
export default Dropdowns